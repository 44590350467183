import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import './OrderProcessInfo.css'
import img1 from './Images/OrderProcess1.jpg'
import img2 from './Images/OrderProcess2.png'
import img3 from './Images/OrderProcess3.png'

const OrderProcessInfo = () => {
    return (
        <Box textAlign={{xs:'center', md:'start',}} sx={{my:7}} >
           <Box >

           <Grid container sx={{display:'flex', alignItems:'top', justifyContent:'center'}}>
               <Grid xs={12} md={6} id="order2">
                    <Typography pl={{xs:0, md:10}}  fontSize={{xs:'30px', md:'40px'}} sx={{fontWeight:'bold', color:'#273438', lineHeight:1.2, pt:7, fontFamily:'Lato'}} >
                    1. Get started by choosing<br/>
                    your desire package.
                    </Typography>
                    <Typography variant="h6" pl={{xs:0, md:10}}  sx={{color:'#7D8284', pt:1,fontFamily:'Lato'}} >

                        Click the “Order On Fiverr” button
                        and you’ll be redirected to the Fiverr Payment.
                    </Typography>
               </Grid>
               <Grid xs={12} md={5} id="order1">
                    <img width="100%"  src={img1} alt=" "/>
               </Grid>
           </Grid>
            <hr/>

           <Grid container sx={{display:'flex', alignItems:'top', justifyContent:'center', }}>
               <Grid xs={12} md={5}  pr={{xs:0, md:10}} >
                    <img width="120%" src={img2} alt=" "/>
               </Grid>
               <Grid xs={12} md={6} id="order2" sx={{pt:7}}>
                    <Typography  fontSize={{xs:'30px', md:'40px'}} sx={{fontWeight:'bold', color:'#273438', lineHeight:1.2, fontFamily:'Lato'}} >
                    
                    2. Join or Processed to Review Order.
                    </Typography>
                    <Typography variant="h6" sx={{ color:'#7D8284', pt:1, fontFamily:'Lato'}} >
                        Join to contine and create account if you don’t have one.
                        If you have existing Fiverr account make the payment with it.3. Join or Processed & Make payment
                    </Typography>
               </Grid>
           </Grid>
           <hr/>


           <Grid container sx={{display:'flex', alignItems:'top', justifyContent:'center',}}>
           <Grid xs={12} md={6} id="order2">
                    <Typography pl={{xs:0, md:10}}  fontSize={{xs:'30px', md:'40px'}} sx={{fontWeight:'bold', color:'#273438', lineHeight:1.2, pt:7, fontFamily:'Lato'}} >
                    3. Submit the requirement page and get it done.
                    </Typography>
               </Grid>
               <Grid xs={12} md={5} id="order1">
                    <img width="100%"  src={img3} alt=" "/>
               </Grid>
           </Grid>
           </Box>
           
        </Box>
    );
};

export default OrderProcessInfo;