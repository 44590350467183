import {   Grid,  Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import './Pricing.css'

const Pricing = () => {

    

    return (
        <Box id="view_pricing" sx={{py:10, fontFamily:'Lato',}}>
            <Typography variant="h3" fontSize={{sm:'30px', md:'60px',}} sx={{fontWeight:'bold', textAlign:'center', color:'#273438', fontFamily:'Lato',}}>
                Pricing
            </Typography>
            <Typography variant="h5"  sx={{ textAlign:'center', color:'#7D8284', pt:2, pb:5, pl:0, fontFamily:'Lato', }}>
            No contracts, no hidden fees, no stress. A great deal.
            </Typography>
            <Grid container sx={{py:7}}  columns={13} columnGap={3} paddingX={{xs:'10px', md:'180px', xl:'220px'}} >
                
                
                {/* For Beginner */}


                <Grid marginLeft={{xs:'15px', md:'0px', xl:'30px'}}   style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', marginTop:'30px', marginBottom:'30px'}} xs={12} md={4} sx={{ borderRadius:'10px'}}>
                    <Typography variant="h4" sx={{textAlign:'center', color: '#273438', pt:5, pb:2, textTransform:'uppercase',  fontFamily:'Lato'}}>
                        beginner
                    </Typography>
                    <Typography variant="h3" sx={{py:2, textAlign:'center',  fontFamily:'Lato'}}>
                        <small style={{fontSize:'40px'}}> <sup>US</sup></small>$33.65
                    </Typography>
                    <Grid container  columnGap={6}>
                    <Typography sx={{  color:'#7D8284', lineHeight:2.5,fontFamily:'Lato',}}  paddingLeft={{xs:9,md:6, xl:13}}fontSize="16px" >
                    2 Design Concept <br/>
                    2 Revisions <br/>
                    Source File <br/>
                    Transparent PNG <br/>
                    High Resolution <br/>
                    Vector File  <br/>
                    3D Mockup <br/>
                    Social Media Kit <br/>
                    Stationery Design <br/>
                    2 days Turnaround <br/>

                    </Typography>
                    <Typography sx={{lineHeight:2.5}}>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#FFB2C2' }}><i class="fas fa-times-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B' }}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#FFB2C2' }}><i class="fas fa-times-circle"></i></span><br/>
                        <span style={{ color:'#FFB2C2' }}><i class="fas fa-times-circle"></i></span> <br/>
                        <span style={{ color:'#FFB2C2' }}><i class="fas fa-times-circle"></i></span> <br/>
                        <span style={{ color:'#FFB2C2' }}><i class="fas fa-times-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                    </Typography>
                    </Grid >
                    <Box sx={{textAlign:'center', py:5}}>
                        <a  style={{textDecoration:'none', color:'black'}} href="https://cutt.ly/rYWJ0K8" >
                    <button className="button" style={{border:'2px solid black', borderRadius:'45px', padding:'15px 50px', textAlign:'center', fontWeight:'bold',  fontFamily:'Lato'}}>

                        Order On Fiverr
                            
                    </button>
                        </a>
                    </Box>
                </Grid>

                {/* For Junior */}

                <Grid marginLeft={{xs:'15px', md:'0px', xl:'0px'}}  style={{boxShadow: ' rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px', border:'2px solid #F59000',}} xs={12} md={4}  sx={{ borderRadius:'10px'}}>
                    <Typography variant="h4" sx={{textAlign:'center', color: '#273438', pt:5, pb:2, textTransform:'uppercase',  fontFamily:'Lato'}}>
                     junior
                    </Typography>
                    <Typography variant="h3" sx={{py:2, textAlign:'center',  fontFamily:'Lato' }}>
                        <small style={{fontSize:'40px'}}> <sup>US</sup></small>$63.33
                    </Typography>
                    <Grid container columnGap={6}>
                    <Typography sx={{  color:'#7D8284', lineHeight:2.5, fontFamily:'Lato',}} paddingLeft={{xs:9,md:6,  xl:13}} fontSize="16px" >
                    2 Design Concept <br/>
                    2 Revisions <br/>
                    Source File <br/>
                    Transparent PNG <br/>
                    High Resolution <br/>
                    Vector File <br/>
                    3D Mockup <br/>
                    Social Media Kit <br/>
                    Stationery Design <br/>
                    2 days Turnaround <br/>

                    </Typography>
                    <Typography sx={{lineHeight:2.5}}>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#FFB2C2' }}><i class="fas fa-times-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B' }}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#FFB2C2' }}><i class="fas fa-times-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                    </Typography>
                    </Grid >
                    <Box sx={{textAlign:'center', py:5}}>
                        <a href =" https://cutt.ly/dYWL4Cb" >

                    <button  className="recommend_button" style={{border:'2px solid #F59000', borderRadius:'45px', padding:'15px 50px', textAlign:'center', fontWeight:'bold', fontFamily:'Lato',}}>
                        Order On Fiverr
                    </button>
                        </a>
                    </Box>
                </Grid>

                {/* For Senior */}

                <Grid marginLeft={{xs:'15px', md:'0px', xl:'0px'}}  style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',  marginTop:'30px', marginBottom:'30px'}} xs={12} md={4} sx={{ borderRadius:'10px'}}>
                    <Typography variant="h4" sx={{textAlign:'center', color: '#273438', pt:5, pb:2, textTransform:'uppercase', fontFamily:'Lato',}}>
                 senior
                    </Typography>
                    <Typography variant="h3" sx={{py:2, textAlign:'center',  fontFamily:'Lato'}}>
                        <small style={{fontSize:'40px'}}> <sup>US</sup></small>$98.11
                    </Typography>
                    <Grid container columnGap={6}>
                    <Typography sx={{  color:'#7D8284', lineHeight:2.5, fontFamily:'Lato',}} paddingLeft={{xs:9,md:6, xl:13}} fontSize="16px" >
                    2 Design Concept <br/>
                    2 Revisions <br/>
                    Source File <br/>
                    Transparent PNG <br/>
                    High Resolution <br/>
                    Vector File  <br/>
                    3D Mockup <br/>
                    Social Media Kit <br/>
                    Stationery Design <br/>
                    2 days Turnaround <br/>

                    </Typography>
                    <Typography sx={{lineHeight:2.5}}>
                    <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B' }}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B' }}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                        <span style={{ color:'#00A37B'}}><i  className="fas fa-check-circle"></i></span> <br/>
                    </Typography>
                    </Grid >
                    <Box sx={{textAlign:'center', pt:7, pb:5}}>
                    <a  style={{textDecoration:'none', color:'black'}} href="https://cutt.ly/RYWXrsI" >
                    <button className="button" style={{border:'2px solid black', borderRadius:'45px', padding:'15px 50px', textAlign:'center', fontWeight:'bold',  fontFamily:'Lato'}}>

                        Order On Fiverr
                            
                    </button>
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Pricing;