import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import img1 from './BrnadLogos/Brand1.png'
import img2 from './BrnadLogos/Brand2.png'
import img3 from './BrnadLogos/Brand3.png'
import img4 from './BrnadLogos/Brand4.png'
import img5 from './BrnadLogos/Brand5.png'
import img6 from './BrnadLogos/Brand6.png'
import img7 from './BrnadLogos/Brand7.png'
import img8 from './BrnadLogos/Brand8.png'
import img9 from './BrnadLogos/Brand9.png'
import img10 from './BrnadLogos/Brand10.png'

const BrandTrusts = () => {
    return (
        <Box p sx={{justifyContent:'center', textAlign:'center', backgroundColor:'#f4f4f4', pb:10}}>
            <Typography variant="h3" fontSize={{xs:'20px', md:'50px'}} sx={{ fontWeight:'bold', color:'#273438', py:10, fontFamily:'Lato',}}>
                They've scaled with us. You can too!
            </Typography>
           
            <Grid container sx={{justifyContent:'center'}} paddingX={{xs:'0px', md:'10px', xl:'210px'}} columnGap={7} rowGap={{xs:15, md:7}}>
                <Grid  display={{xs:'none', md:'none', xl:'block'}} xs={12} md={2}>
                    <img src={img1 } alt=" " />
                </Grid>
                <Grid xs={12} md={2}>
                    <img src={ img2} alt=" " />
                </Grid>
                <Grid xs={12} md={2}>
                    <img src={img3 } alt=" " />
                </Grid>
                <Grid xs={12} md={2}>
                    <img src={img4 } alt=" " />
                </Grid>
                <Grid display={{xs:'none', md:'none', xl:'block'}} xs={12} md={2}>
                    <img src={ img5} alt=" " />
                </Grid>
                <Grid xs={12} md={2}>
                    <img src={img6 } alt=" " />
                </Grid>
                <Grid display={{xs:'none', md:'none', xl:'block'}} xs={12} md={2}>
                    <img src={img7 } alt=" " />
                </Grid>
                <Grid xs={12} md={2}>
                    <img src={ img8} alt=" " />
                </Grid>
                <Grid display={{sm:'none', md:'none', xl:'block'}} xs={12} md={2}>
                    <img src={img9 } alt=" " />
                </Grid>
                <Grid xs={12} md={2}>
                    <img src={img10 } alt=" " />
                </Grid>
                
            </Grid>
        </Box>
    );
};

export default BrandTrusts;