import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Blogs = () => {
    return (
        <Box sx={{py:20}}>
            <Typography variant="h3" sx={{fontWeight:'bold', color:'#F59000', textAlign:'center', fontFamily:'Lato'}} fontSize={{xs:'25px', md:'48px'}}>
                Blog Coming Soon!!
            </Typography>
        </Box>
    );
};

export default Blogs;