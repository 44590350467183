import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
// import './CustomeLogoBanner.css';
import customLogoBanner2 from './customLogoBanner2.jpg'

const CustomLogoBanner2 = () => {
    return (
        <Box   sx={{ flexGrow: 1, backgroundColor:'#f4f4f4', }} p={{xs:3, md:10}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Typography variant="h3" fontSize={{xs:'30px', md:'48px'}}   pt={{xs:10, md:10}} sx={{fontWeight:'bold', lineHeight:1.3, pb:2, color:'#273438',  fontFamily:'Lato'}}>
          Your logos will start with a<br/>
          hand-drawn sketch
          </Typography>
          <Typography variant="h6" fontSize={{xs:'12px', md:'17px'}}   sx={{ lineHeight:1.5, color:'#7D8284',  fontFamily:'Lato' }}>
          Once you have place the order and sent us your brief. Your logo will start from a <br/> hand-drawn sketch to ensure it's completely unique, authentic and creative.
          </Typography>
        </Grid>
        <Grid    xs={12} md={5} sx={{pt:2,}} >
        <img width="100%" style={{ borderRadius:'10px'}} src={customLogoBanner2} alt=" "/>
        </Grid>
      </Grid>
    </Box>
    );
};

export default CustomLogoBanner2;