import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const AboutBanner = () => {
    return (
        <Box sx={{backgroundColor:'#012A55', color:'white',}} pt={{xs:10, md:15}} pb={{xs:10, md:10}} textAlign={{xs:'center', md:'center'}}>
            <Typography variant="h3" fontSize={{xs:'25px', md:'48px'}} sx={{fontWeight:'bold', pb:1, fontFamily:'Lato'}}>
                Hello!
            </Typography>
            <Typography variant="h3" fontSize={{xs:'25px', md:'48px'}} sx={{fontWeight:'bold', pb:1, fontFamily:'Lato'}} px={{xs:2, md:35}}>
            finder3suvas is the brain-child of Md. Suvas Bin Monir.
            </Typography>
            <Typography variant="h6"  px={{xs:2, md:45}} sx={{fontFamily:'Lato'}}>
            Graphic design and business are two of my biggest passions, so I design custom logos that help businesses succeed

            </Typography>
        </Box>
    );
};

export default AboutBanner;