import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { SRLWrapper } from "simple-react-lightbox";


import img1 from './Port/p1.jpg';
import img2 from './Port/p2.jpg';
import img3 from './Port/p3.jpg';
import img41 from './Port/p4.1.jpg';
import img4 from './Port/p4.jpg';
import img5 from './Port/p5.jpg';
import img6 from './Port/p6.jpg';
import img7 from './Port/p7.jpg';
import img8 from './Port/p8.jpg';
import img9 from './Port/p9.jpg';
import img121 from './Port/p12.1.jpg';
import img10 from './Port/p10.jpg';
import img11 from './Port/p11.jpg';
import img12 from './Port/p12.jpg';
import img13 from './Port/p13.jpg';
import img14 from './Port/p14.jpg';
import img15 from './Port/p15.jpg';
import img16 from './Port/p16.jpg';
import img17 from './Port/p17.jpg';

const PortfolioHomeSample = () => {


    const options = {
        buttons: {
      
          showAutoplayButton: false,
          showCloseButton: true,
          showDownloadButton: false,
          showFullscreenButton: true,
          showNextButton: true,
          showPrevButton: true,
          showThumbnailsButton: false,
          size: '40px'
        }
    }
    
    return (
        <Box textAlign={{xs:'center', md:'center'}} id="prot">
            <Typography variant="h4" fontSize={{xs:'20px', md:'50px'}} sx={{ fontWeight:'bold', color:'#273438', pt:10, pb:2, fontFamily:'Lato'}}>
            Get inspired by our custom designs.
            </Typography>
            <Typography variant="h5" px={{sx:1, md:20, xl:20}} sx={{  color:'#7D8284',pb:10,fontFamily:'Lato'}}>

            These are some of the recent projects we have done for startups, small businesses and large companies around the world.
            </Typography>

            <SRLWrapper options={options} >

  
            <Grid  container columns={13} sx={{display:'flex', justifyContent:'space-evenly'}} rowGap={3}>

{/* first row */}

            <Grid md={4}  sx={{border:1, height:'400px'}}>
            <img src={  img1  }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4}  sx={{border:1}}>
            <img src={ img2   }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4} sx={{border:1}}>
            <img src={  img3  }  class="d-block w-100" alt="..."/>
            </Grid>


{/* second row */}
            <Grid md={8.25}  sx={{border:1, height:'400px'}}>
            <img src={ img41   }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4}  sx={{border:1}}>
            <img src={  img5 }  class="d-block w-100" alt="..."/>
            </Grid>


{/* third row */}
            <Grid md={4}  sx={{border:1, height:'400px'}}>
            <img src={  img6  }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4}  sx={{border:1}}>
            <img src={  img7  }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4} sx={{border:1}}>
            <img src={ img8   }  class="d-block w-100" alt="..."/>
            </Grid>
            
{/* first row */}

            <Grid md={4}  sx={{border:1, height:'400px'}}>
            <img src={  img9  }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4}  sx={{border:1}}>
            <img src={ img10   }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4} sx={{border:1}}>
            <img src={  img11  }  class="d-block w-100" alt="..."/>
            </Grid>


{/* second row */}
            <Grid md={8.25}  sx={{border:1, height:'400px'}}>
            <img src={  img121  }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4}  sx={{border:1}}>
            <img src={  img12  }  class="d-block w-100" alt="..."/>
            </Grid>


{/* third row */}
            <Grid md={4}  sx={{border:1, height:'400px'}}>
            <img src={ img13   }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4}  sx={{border:1}}>
            <img src={ img14   }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4} sx={{border:1}}>
            <img src={  img15  }  class="d-block w-100" alt="..."/>
            </Grid>

{/* fourth row */}
            <Grid md={4}  sx={{border:1, height:'400px'}}>
            <img src={ img4   }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4}  sx={{border:1}}>
            <img src={ img16   }  class="d-block w-100" alt="..."/>
            </Grid>
            <Grid md={4} sx={{border:1}}>
            <img src={  img17  }  class="d-block w-100" alt="..."/>
            </Grid>

            </Grid>
            </SRLWrapper>
        </Box>
    );
};

export default PortfolioHomeSample;