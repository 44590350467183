import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const PortfolioHomeBanner2 = () => {
    return (
        <Box sx={{textAlign:'center', py:10,backgroundColor:'#f4f4f4', my:10}}>
          <Typography variant="h3" fontSize={{xs:'20px', md:'48px'}} sx={{color:'#F59000', fontFamily:'Lato'}} px={{xs:2, md:20}}>
          Have A Project in Mind? 
            </Typography>
            <Typography variant="h5" fontSize={{xs:'18px'}}  sx={{color:'#7D8284', pt:1,fontFamily:'Lato'}} px={{xs:2, md:20}}>
            Place the order and get your project done by a dedicated graphic design team
            </Typography><br/><br/>
            <Button id="get_start" style={{fontSize:'20px', backgroundColor:'#F59000',fontFamily:'Lato', }} sx={{padding:'10px 20px',}}>
             <HashLink style={{color:'black', fontWeight:'bold', textDecoration:'none', paddingRight:'30px'}} to="/pricing#view_pricing">
                 Choose A Pakage
                 </HashLink>
            </Button> 
        </Box>
    );
};

export default PortfolioHomeBanner2;