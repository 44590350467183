import { Box } from '@mui/system';
import React from 'react';
import NavBar from '../../Home/Navbar/Navbar';
import Fotter from '../../Shared/Fotter/Fotter';
import Pricing from '../../Shared/Pricing/Pricing';
import OrderProcessHomeBanner from '../OrderProcessHomeBanner/OrderProcessHomeBanner';
import OrderProcessInfo from '../OrderProcessInfo/OrderProcessInfo';

const OrderProcessHome = () => {
    return (
        <Box id="order_process">
            <NavBar/>
            <OrderProcessHomeBanner/>
            <OrderProcessInfo/>
            <Box sx={{backgroundColor:'#f4f4f4'}}>
            <Pricing/>
            </Box>
            <Fotter/>
        </Box>
    );
};

export default OrderProcessHome;