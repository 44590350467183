import { Box } from '@mui/system';
import React from 'react';
import NavBar from '../../Home/Navbar/Navbar';
import Fotter from '../../Shared/Fotter/Fotter';
import ContactForm from '../ContactForm/ContactForm';
import ContactHomeBanner from '../ContactHomeBanner/ContactHomeBanner';

const ContactUsHome = () => {
    return (
        <Box id="conatact">
            <NavBar/>
            <ContactHomeBanner/><br/><br/>
            <ContactForm/><br/><br/>
            <Fotter/>
        </Box>
    );
};

export default ContactUsHome;