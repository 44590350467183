import {  Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import fotterLogo from './headerLogo.png'
import './Fotter.css'
import { HashLink } from 'react-router-hash-link';



const Fotter = () => {
    return (
        <Box sx={{backgroundColor:'#012A55', color:'white',}} pt={{xs:5}}>
            <Grid container columns={12} paddingLeft={{xs:1, md:35, xl:55}} paddingY={{xs:1, md:10, xl:10}} columnGap={3} >


                <Grid xs={12} md={4} sx={{px:4}} pb={{xs:3}}>
         
                    <img   src={fotterLogo} alt=""/>
                
                <hr/>
                <br/>
                
               <Box>
               <Typography variant="h6" sx={{pb:3,  fontFamily:'Lato'}}>
                <i className="far fa-envelope fa-lg"></i> &nbsp; <small style={{color:'#f4f4f4', fontFamily:'Lato'}}>finder.suvo@gmail.com</small>
                </Typography> 
                <Typography variant="h6" sx={{pb:3,  fontFamily:'Lato'}}>
                <i className="fab fa-whatsapp fa-lg"></i> &nbsp; <small style={{color:'#f4f4f4', fontFamily:'Lato'}}>+880-1722302673</small>
                </Typography>
                <Typography variant="h6" sx={{pb:2, fontFamily:'Lato'}}>
                <i className="fas fa-map-marker-alt fa-lg"></i> &nbsp; <small style={{color:'#f4f4f4', fontFamily:'Lato'}}>787, Terokhadia Uttorpara, Rajpara, Rajshahi, Bangladesh.</small>
                </Typography>
               </Box>
                </Grid>


                <Grid xs={12} md={2} pl={{xs:4}} pb={{xs:3}} sx={{ fontFamily:'Lato'}}>
                <Typography variant="h5" sx={{fontFamily:'Lato'}}>
                   Logo Service
                </Typography><br/>
                <Box sx={{lineHeight:2}}>
                <HashLink style={{fontSize:'17px', padding:'0px'}}  to="/custom_logo#custom_logo" >
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer', fontFamily:'Lato'}}> 
                Cutom Logo
                </button><br/>
                </HashLink>
                <HashLink style={{fontSize:'17px', padding:'0px'}}  to="/order_process#order_process" >
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer', fontFamily:'Lato'}}> 
                   Order Process
                </button><br/>
                </HashLink>
                <HashLink style={{fontSize:'17px', padding:'0px'}}  to="/pricing#pricing" >
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer', fontFamily:'Lato'}}> 
                    Pricing
                </button><br/>
                </HashLink>
                <HashLink style={{fontSize:'17px', padding:'0px'}}  to="/portfolio#portfolio" >
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer', fontFamily:'Lato'}}> 
                    Contact Us
                </button><br/>
                </HashLink>
                <HashLink style={{fontSize:'17px', padding:'0px'}}  to="/faq#faq" >
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer', fontFamily:'Lato'}}> 
                    About Us
                </button><br/>
                </HashLink>
                <HashLink style={{fontSize:'17px', padding:'0px'}}  to="/about#about" >
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer', fontFamily:'Lato'}}> 
                    FAQ
                </button><br/>
                </HashLink>
                <HashLink style={{fontSize:'17px', padding:'0px'}}  to="/blog#blog" >
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer', fontFamily:'Lato'}}> 
                    Blog
                </button><br/>
                </HashLink>
                
                </Box>
                </Grid>


               


                <Grid xs={12} md={3} pr={{xs:5}} pl={{xs:0, md:3, xl:3}}>
                <Typography sx={{fontFamily:'Lato'}} variant="h5"  pl={{xs:4,}}>
                Follow Us
                </Typography><br/>

 <Box display={{xs:'flex', md:'block', xl:'block'}}>
     
 <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer'}}> 
                <Typography variant="h6" sx={{pb:2}}>
                    <a style={{textDecoration:'none', color:'white'}} href="https://dribbble.com/finder3suvas" target="_blank" rel="noreferrer"s >
                <i className="fab fa-dribbble fa-lg"></i> &nbsp; 
                    </a>
                </Typography>
                </button><br/>
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer'}}> 
                <Typography variant="h6" sx={{pb:2}}>
                    <a style={{textDecoration:'none', color:'white'}} href="https://www.behance.net/finder3suvas" rel="noreferrer" target="_blank" >
                <i className="fab fa-behance fa-lg"></i> &nbsp; 
                    </a>
                </Typography>
                </button><br/>
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer'}}> 
                <Typography variant="h6" sx={{pb:2}}>
                    <a style={{textDecoration:'none', color:'white'}} href="https://www.flickr.com/photos/169946543@N02/albums/72157709870409092" target="_blank" rel="noreferrer" >
                <i className="fab fa-flickr fa-lg"></i> &nbsp; 
                    </a>
                </Typography>
                </button><br/>
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer'}}> 
                <Typography variant="h6" sx={{pb:2}}>
                    <a style={{textDecoration:'none', color:'white'}} href="https://www.instagram.com/finder3suvas/" target="_blank" rel="noreferrer" >
                <i className="fab fa-twitter fa-lg"></i> &nbsp; 
                    </a>
                </Typography>
                </button><br/>
                <button style={{color:'#f4f4f4',border:'0px', backgroundColor:'#012A55', cursor:'pointer'}}> 
                <Typography variant="h6" sx={{pb:2}}>
                    <a style={{textDecoration:'none', color:'white'}} href="https://twitter.com/finder3suvas" target="_blank" rel="noreferrer" >
                <i className="fab fa-instagram fa-lg"></i> &nbsp; 
                    </a>
                </Typography>
                </button><br/>
         
 </Box>

                </Grid>


                
            </Grid>
            
        </Box>
    );
};

export default Fotter;