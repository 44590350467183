import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import img1 from './Icon/Icon1.svg'
import img2 from './Icon/Icon2.svg'
import img3 from './Icon/Icon3.svg'
import img4 from './Icon/Icon4.svg'


const ServiceWork = () => {
    return (
        <Box id="get_start" sx={{textAlign:'center', fontFamily:'Lato',}}>
          <Box sx={{md:7}}>
          <Typography variant="h3" fontSize={{xs: '25px', md:'50px'}} sx={{fontWeight:'bold', color:'#273438', pb:1, fontFamily:'Lato',}} >
           Get a custom logo design for less
           </Typography>
           <Typography variant="paragraph" style={{fontSize:'18px', fontFamily:'Lato',}} paddingX={{xs:'20px'}}  sx={{ color:'#7D8284'}} >
           Use finder3suvas creative studio to get your custom logo at a price that fits your budget and turnaround.
           </Typography>
          </Box>



           <Grid container   columns={13} sx={{pt:12,}} columnGap={{md:3}} rowGap={{xs:4}} padding={{md:10, xs:10}} >
            <Grid sx={12} md={3}>
                <img width="150px" src={img1} alt=" " />
                <Typography variant="h5" sx={{fontWeight:'medium', py:5, color:'#273438', pb:1, fontFamily:'Lato',}} >
                    Order & Brief
                    </Typography>
                    <Typography variant="paragraph"style={{fontSize:'18px'}}  sx={{fontWeight:'medium', color:'#7D8284', pb:1, fontFamily:'Lato',}} >
                    Place your order & fill the requirement page.
                    </Typography>
            </Grid>
            <Grid sx={12} md={3}>
            <img width="150px" src={img2} alt=" " />
            <Typography variant="h5" sx={{fontWeight:'medium', py:5, color:'#273438', pb:1, fontFamily:'Lato',}} >
                Initial Concepts
                </Typography>
                <Typography variant="paragraph"style={{fontSize:'18px'}}  sx={{fontWeight:'medium', color:'#7D8284', pb:1, fontFamily:'Lato',}} >
                Receive your initial concept within your turnaround.
                </Typography>
            </Grid>
            <Grid sx={12} md={3}>
            <img width="150px" src={img3} alt=" " />
                <Typography variant="h5" sx={{fontWeight:'medium', py:5, color:'#273438', pb:1, fontFamily:'Lato',}} > 
                Feedback & Revisions
                </Typography>
                <Typography variant="paragraph"style={{fontSize:'18px'}}  sx={{fontWeight:'medium', color:'#7D8284', pb:1}} >
                Unlimited revisions based on your needs.
                </Typography>
            </Grid>
            <Grid sx={12} md={3}>
            <img width="150px" src={img4} alt=" " />
                <Typography variant="h5" sx={{fontWeight:'medium', py:5, color:'#273438', pb:1, fontFamily:'Lato',}} >
                Download Design
                </Typography>
                <Typography variant="paragraph"style={{fontSize:'18px'}}  sx={{fontWeight:'medium', color:'#7D8284', pb:1}} >
                Receive a set of file formats ready for web & Print
                </Typography>
            </Grid>
           </Grid>


        </Box>
    );
};

export default ServiceWork;