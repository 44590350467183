import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const PriceContact = () => {
    return (
        <Box sx={{textAlign:'center', py:10,backgroundColor:'#f4f4f4'}}>
            <Typography variant="h3" fontSize={{xs:'20px', md:'48px'}} sx={{color:'#F59000', fontFamily:'Lato'}} px={{xs:2, md:20}}>
            Do you have any specefic budget or custom graphic design project? 
            </Typography>
            <Typography variant="h5" fontSize={{xs:'18px'}}  sx={{color:'#7D8284', pt:1, fontFamily:'Lato'}} px={{xs:2, md:20}}>
            We can help. Just stay in touch and we'll do it together. 
            </Typography>
            <Button id="get_start" style={{fontSize:'20px', backgroundColor:'#F59000', padding:'10px 20px',}} sx={{mt:5}}>
             <HashLink   style={{color:'black', fontWeight:'bold', textDecoration:'none', fontFamily:'Lato', paddingRight:'30px'}} to="/conatact#conatact">
             Contact Us
                 </HashLink>
            </Button>
        </Box>
    );
};

export default PriceContact;