import { Box } from '@mui/system';
import React from 'react';
import FaqQuestion from '../../FAQ/FaqQuestion/FaqQuestion';
import NavBar from '../../Home/Navbar/Navbar';
import Fotter from '../../Shared/Fotter/Fotter';
import Pricing from '../../Shared/Pricing/Pricing';
import PriceContact from '../PriceContact/PriceContact';
import PricingHomeBanner from '../PricingHomeBanner/PricingHomeBanner';

const PricingHome = () => {
    return (
        <Box id="pricing">
            <NavBar/>
            <PricingHomeBanner/>
            <Pricing/>
            <PriceContact/><br/><br/><br/>
            <FaqQuestion/>
            <Fotter/>
        </Box>
    );
};

export default PricingHome;