import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { HashLink  } from 'react-router-hash-link';
import logo from '../../../images/headerLogo.png'
import './Navbar.css'
import { Divider } from '@mui/material';



const NavBar = () => {


  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Drawer 

  const [state, setState] = React.useState(false);
  const list =  (
    <Box
      sx={{ width:  250 }}
      role="presentation"
    >
     
      <List>
            <HashLink   to="/#home"> Home</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/custom_logo#custom_logo"> Custom Logo</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/order_process#order_process"> Order Process</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/pricing#pricing"> Pricing</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/portfolio#portfolio"> Portfolio</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/faq#faq"> FAQ</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/about#about"> About</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/conatact#conatact"> Contact</HashLink ><br/><br/>
            <Divider/><br/>
            <HashLink   to="/blog#blog"> Blog</HashLink ><br/><br/>
            <Divider/><br/>
      </List>

     
    </Box>
  );

 const bgColor = {
   backgroundColor:'#001C29'
 }
  return (
    <>
      
        
    <AppBar position="fixed"  style={bgColor}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <div>
     
    </div>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
            style={{paddingLeft:'70px'}}
          >
           <img width="220" height="40" src={logo} alt=""/>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <React.Fragment>
          <Button onClick={() => setState(true)}>
          <IconButton
              size="large"
              style={{color:'white'}}
            >
              <MenuIcon />
            </IconButton>
          </Button>
          <Drawer
           open={state}
            onClose={() => setState(false)}
          >

            {list}
          </Drawer>
        </React.Fragment>
           
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
             
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', }  }}
          >
           <img   src={logo} alt=""/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {/* {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page} */}
              {/* </Button>
            ))} */}
          </Box>

          <Box style={{textTransform:'uppercase',  padding:'0px 70px' }} display={{xs:'none', md:'block'}} >
          
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/#home">home</HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/custom_logo#custom_logo">Coustom Logo</HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/order_process#order_process">Order Process</HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/pricing#pricing">Pricing</HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/portfolio#portfolio">Protfolio</HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/faq#faq">FAQ</HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/about#about">About </HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/conatact#conatact">Contact </HashLink >
     <HashLink  sx={{pl:5, fontWeight:'light'}} style={{textDecoration:'none', color:'white', paddingLeft:'30px', fontSize:'13px' }} to="/blog#blog">Blog</HashLink >
           
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    </>
  );
};
export default NavBar;
