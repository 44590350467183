import { Box } from '@mui/system';
import React from 'react';
import NavBar from '../../Home/Navbar/Navbar';
import PortfolioHomeBanner from '../PortfolioHomeBanner/PortfolioHomeBanner';
import PortfolioHomeSample from '../PortfolioHomeSample/PortfolioHomeSample';
import SimpleReactLightbox from 'simple-react-lightbox'
import PortfolioHomeBanner2 from './PortfolioHomeBanner2/PortfolioHomeBanner2';
import Fotter from '../../Shared/Fotter/Fotter';
import Testimonial from '../../Home/Testimonial/Testimonial';

const PortfolioHome = () => {
    return (
        <Box id="portfolio">
            <NavBar/>
            <PortfolioHomeBanner/>
            <SimpleReactLightbox>
            <PortfolioHomeSample/>
            </SimpleReactLightbox>
            <PortfolioHomeBanner2/>
            <Testimonial/>
            <Fotter/>
        </Box>
    );
};

export default PortfolioHome;