import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { Box } from '@mui/system';
import TextField from '@mui/material/TextField';
import { TextareaAutosize } from '@mui/base';
import {  Grid, Typography } from '@mui/material';
import './ContactFrom.css'


const ContactForm = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(`service_q8y1qcc`, `template_cr22qja`, form.current, `user_gt4irDh4mb3s75Q9ekHO6` )
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
    };

    return (
        <Box id="conatactUS" >
          <Grid container sx={{display:'flex', justifyContent:'center', alignItems:'top',}} rowGap={5}>
            <Grid  xs={12} md={6.5}>
            <Typography variant="h3" fontSize={{xs:'25px', md:'48px'}} sx={{color:'#F59000', fontWeight:'bold', fontFamily:'Lato'}}>
                  We're just one E-mail away!
                </Typography><br/><br/>
        <form ref={form} onSubmit={sendEmail}>
        <TextField
          className="input"
          required
          type="name"
          name="name" 
          id="outlined-required"
          label="YOUR NAME"
          style={{marginRight:'50px'}}
        />
        <TextField
          className="input"
          required
          type="email"
          name="email" 
          id="outlined-required"
          label="YOUR EMAIL"
          
        /><br/><br/>
        <TextField
          className="input2"
          required
          type="subject"
          name="subject" 
          id="outlined-required"
          label="SUBJECT"
      
        /><br/><br/>
        <TextareaAutosize
          className="input2"
          id="text_area"
          required
          type="message"
          name="message"          
          aria-label="empty textarea"
          placeholder="MESSAGE"
          minRows={7}
          
        /><br/><br/>
        
              
   
          <input  style={{fontSize:'20px', backgroundColor:'#F59000', color:'black', fontWeight:'bold', padding:'10px 20px',}} type="submit" value="Send Message" />
        </form>
            </Grid>

            <Grid xs={12} md={4} sx={{pl:5}} borderLeft={{xs:'none', md:'2px solid #7D8284'}} style={{ height:'435px'}} >
                <Typography variant="h3" fontSize={{xs:'25px', md:'48px'}} sx={{fontWeight:'bold', color:'#F59000', fontFamily:'Lato'}}>
                 Need help
                </Typography><br/><br/>
                <Box>
               <Typography variant="h6" sx={{pb:3, fontFamily:'Lato'}}>
                <i className="far fa-envelope fa-lg"></i> &nbsp; <small style={{color:'#7D8284'}}>finder.suvo@gmail.com</small>
                </Typography> 
                <Typography variant="h6" sx={{pb:3, fontFamily:'Lato'}}>
                <i className="fab fa-whatsapp fa-lg"></i> &nbsp; <small style={{color:'#7D8284'}}>+880-1722302673</small>
                </Typography>
                <Typography variant="h6" sx={{pb:2,fontFamily:'Lato'}}>
                <i className="fas fa-map-marker-alt fa-lg"></i> &nbsp; <small style={{color:'#7D8284'}}>787, Terokhadia Uttorpara, Rajpara, Rajshahi, Bangladesh.</small>
                </Typography>
               </Box>
            </Grid>
          </Grid>
        </Box>
    );
};

export default ContactForm;