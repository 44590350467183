import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import img1 from './service_icon/service1.svg'
import img2 from './service_icon/service2.svg'
import img3 from './service_icon/service3.svg'
import img4 from './service_icon/service4.svg'
import img5 from './service_icon/service5.svg'
import img6 from './service_icon/service6.svg'

const Services = () => {
    return (
        <Box textAlign={{sx:'center'}} sx={{backgroundColor:'#f4f4f4', textAlign:'center', py:10, fontFamily:'Lato',}}>
            <Typography variant="h3" fontSize={{xs:'40px', md:'50px', xl:'50px'}} sx={{fontWeight:'bold', color:'#273438',  mb:2, fontFamily:'Lato',}}>
                Our desing serivces
            </Typography>
            <Typography variant="h5"  sx={{ color:'#7D8284',  mb:10, fontFamily:'Lato',}}>
            Offering a variety of services at an affordable price is our goal.
            </Typography>
            <Grid container sx={{textAlign:'center', justifyContent:'center', }}  columns={12} rowGap={9}  paddingX={{xs:'20px', md:'200px', xl:'370px'}}>
                <Grid   xs={12} md={4}>
                    <Box width={{xs:'100%', md:'270px', xl:'270px'}} sx={{border:1, p:2, }}>
                    <img  width=" 150px" src={img1} alt=" "/> 
                    <Typography variant="h6" sx={{fontWeight:'bold',fontFamily:'Lato',}}>
                    Custom Logo & Branding
                    </Typography>
                    </Box>
                </Grid>
                <Grid  xs={12} md={4}>
                    <Box width={{xs:'100%', md:'270px', xl:'270px'}} sx={{border:1, p:2}}>
                    <img  width=" 150px" src={img2} alt=" "/> 
                    <Typography variant="h6" sx={{fontWeight:'bold',fontFamily:'Lato',}}>
                    Webstie Development
                    </Typography>
                    </Box>
                </Grid>                
                <Grid  xs={12} md={4}>
                    <Box width={{xs:'100%', md:'270px', xl:'270px'}} sx={{border:1, p:2}}>
                    <img  width=" 150px" src={img3} alt=" "/> 
                    <Typography variant="h6" sx={{fontWeight:'bold',fontFamily:'Lato',}}>
                    Stationery Design
                    </Typography>
                    </Box>
                </Grid>
                <Grid  xs={12} md={4}>
                    <Box width={{xs:'100%', md:'270px', xl:'270px'}} sx={{border:1, p:2}}>
                    <img  width=" 150px" src={img4} alt=" "/> 
                    <Typography variant="h6" sx={{fontWeight:'bold',fontFamily:'Lato',}}>
                    Flyer & Brochure Design
                    </Typography>
                    </Box>
                </Grid>
                <Grid  xs={12} md={4}>
                    <Box width={{xs:'100%', md:'270px', xl:'270px'}} sx={{border:1, p:2}}>
                    <img  width=" 150px" src={img5} alt=" "/> 
                    <Typography variant="h6" sx={{fontWeight:'bold',fontFamily:'Lato',}}>
                    Product Packaging
                    </Typography>
                    </Box>
                </Grid>
                <Grid  xs={12} md={4}>
                    <Box width={{xs:'100%', md:'270px', xl:'270px'}} sx={{border:1, p:2}}>
                    <img  width=" 150px" src={img6} alt=" "/> 
                    <Typography variant="h6" sx={{fontWeight:'bold',fontFamily:'Lato',}}>
                    T-Shirt Design
                    </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Services;