import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import suvas from './TeamPhoto/suvas.jpg'
import mahbur from './TeamPhoto/mahbur.jpg'
import rafiur from './TeamPhoto/rafiur.jpg'
import subrina from './TeamPhoto/subrina.jpg'
import ikbal from './TeamPhoto/ikbal.jpg'
import sojib from './TeamPhoto/sojib.jpg'

const AboutTeam = () => {
    return (
        <Box sx={{py:10, backgroundColor:'#f4f4f4'}}>
            <Typography variant="h3" sx={{color:'#F59000', fontWeight:'bold', textAlign:'center', pb:5, fontFamily:'Lato'}}>
                Meet Our Team 
            </Typography>
            <Grid container columns={11} sx={{dispaly:'flex', justifyContent:'center'}} rowGap={2} >
                <Grid xs={12} md={3} sx={{textAlign:'center', p:3}}>
                    <img width="200px" style={{paddingBottom:'10px'}} src={suvas} alt=" "/>
                    <Typography variant="h5" sx={{fontFamily:'Lato'}}>
                   <span style={{fontWeight:'bold'}}> Md. Suvas Bin Monir</span><br/>
                        Founder & CEO <br/> <small>( Sr. Desinger )</small>
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} sx={{textAlign:'center', p:3}}>
                    <img width="200px" style={{paddingBottom:'10px'}} src={mahbur} alt=" "/>
                    <Typography variant="h5" sx={{fontFamily:'Lato'}}>
                    <span style={{fontWeight:'bold'}}> Mahabur Rahman</span><br/>
                       ( Sr. Graphic Desinger )
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} sx={{textAlign:'center', p:3}}>
                    <img width="200px" style={{paddingBottom:'10px'}} src={rafiur} alt=" "/>
                    <Typography variant="h5" sx={{fontFamily:'Lato'}}>
                    <span style={{fontWeight:'bold'}}> Rafiur Rahman</span><br/>
                    ( Sr. Marketer )
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} sx={{textAlign:'center', p:3}}>
                    <img width="200px" style={{paddingBottom:'10px'}} src={subrina} alt=" "/>
                    <Typography variant="h5" sx={{fontFamily:'Lato'}}>
                    <span style={{fontWeight:'bold'}}>Subrina Nipa</span><br/>
                    ( Jr. Graphic Desinger )
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} sx={{textAlign:'center', p:3}}>
                    <img width="200px" style={{paddingBottom:'10px'}} src={sojib} alt=" "/>
                    <Typography variant="h5" sx={{fontFamily:'Lato'}}>
                    <span style={{fontWeight:'bold'}}> Sojib Uddin</span><br/>
                    ( Sr. Developer )
                    </Typography>
                </Grid>
                <Grid xs={12} md={3} sx={{textAlign:'center', p:3}}>
                    <img width="200px" style={{paddingBottom:'10px'}} src={ikbal} alt=" "/>
                    <Typography variant="h5" sx={{fontFamily:'Lato'}}>
                    <span style={{fontWeight:'bold'}}> Ikbal Hasan</span><br/>
                    ( Sr. Graphic Desinger )
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AboutTeam;