import React from 'react';
import './HomePortfolio.css'
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { HashLink } from 'react-router-hash-link';

import img1  from './HomePortfolioImg/img1.jpg'
import img11  from './HomePortfolioImg/img1.1.jpg'
import img2 from './HomePortfolioImg/img2.jpg'
import img22  from './HomePortfolioImg/img2.1.jpg'
import img3  from './HomePortfolioImg/img3.jpg'
import img33  from './HomePortfolioImg/img3.1.jpg'
import img4  from './HomePortfolioImg/img4.jpg'
import img44  from './HomePortfolioImg/img4.1.jpg'
import img5  from './HomePortfolioImg/img5.jpg'
import img55  from './HomePortfolioImg/img5.1.jpg'
import img6  from './HomePortfolioImg/img6.jpg'
import img66  from './HomePortfolioImg/img6.1.jpg'
import img7  from './HomePortfolioImg/img7.jpg'
import img77  from './HomePortfolioImg/img7.1.jpg'
import img777  from './HomePortfolioImg/img7.2.jpg'
import img8  from './HomePortfolioImg/img8.jpg'
import img88  from './HomePortfolioImg/img8.1.jpg'


const HomePortfolio = () => {
  return (
    <Box sx={{textAlign:'center', pb:10}}>

      <Typography variant="h4" fontSize={{xs:'20px', md:'50px'}} sx={{ fontWeight:'bold', color:'#273438', pt:10, pb:2, fontFamily:'Lato'}}>
      Get inspired by our custom designs.
      </Typography>
      <Typography variant="h5" px={{sx:1, md:20, xl:20}} sx={{  color:'#7D8284',pb:10,fontFamily:'Lato'}}>
                
          These are some of the recent projects we have done for startups, small businesses and large companies around the world.
                            </Typography>






     <Grid  container columns={13} sx={{display:'flex', justifyContent:'space-evenly'}} rowGap={3}>
    <Grid md={4}  sx={{border:1, height:'400px'}}>
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img1}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img11} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>
    
    
    <Grid md={4}  sx={{border:1, height:'400px'}}>
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img2}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img22} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>


    <Grid md={4}  sx={{border:1, height:'400px'}}>
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img3}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img33} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>







    <Grid md={8.25}  sx={{border:1, height:'400px'}}>
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img4}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img44} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>
    <Grid md={4}  sx={{border:1}}>
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img5}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img55} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>








    <Grid md={4}  sx={{border:1, height:'400px'}}>
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img6}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img66} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>


    <Grid md={4}  sx={{border:1}}>
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img7}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img77} class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img src={img777} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>


    <Grid md={4} sx={{border:1}}>
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src={img8}class="d-block w-100" alt="..."/>
                </div>
                <div class="carousel-item">
                    <img  src={img88} class="d-block w-100" alt="..."/>
                </div>
            </div>
        </div>
    </Grid>






     </Grid>
     <br/><br/>
     <Button id="get_start" style={{fontSize:'20px', backgroundColor:'#F59000', }} sx={{padding:'10px 20px',fontFamily:'Lato'}}>
             <HashLink style={{color:'black', fontWeight:'bold', textDecoration:'none', paddingRight:'30px'}} to="/portfolio#prot">
             View All Recent Designs
                 </HashLink>
            </Button>
  
    </Box>
  );
};

export default HomePortfolio;