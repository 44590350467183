import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import faqlogo from './FAQ.png'

const FaqHomeBanner = () => {
    return (
       

            <Box height={{xs:"100%", md:"516px"}} className="Banner" sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={7}>
          <Typography variant="h3" fontSize={{xs:'25px', md:'48px'}} pl={{xs:0, md: 10,}} pt={{xs:10, md:20}} sx={{fontWeight:'bold', lineHeight:1.3, pb:2,fontFamily:'Lato'}}  width={{xs:'100%', md:'70%'}}>
          Frequently Asked Questions
          </Typography>
          <Typography variant="h6" fontSize={{xs:'15px', md:'17px'}} pl={{xs:1, md: 10,}} pr={{xs:1}}  sx={{ lineHeight:1.5, color: '#f4f4f4',fontFamily:'Lato'}}  width={{xs:'100%', md:'75%'}}>
          Are you have any questions about us. That how we work and what you get from us? We’ve got all the answers you need.
          </Typography>
        </Grid>
        <Grid    xs={12} md={5} >
        <img  className="CustomLogoBannerImg" src={faqlogo} alt=" "/>
        </Grid>
      </Grid>
    </Box>
        
    );
};

export default FaqHomeBanner;