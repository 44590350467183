import React from 'react';
import Fotter from '../../Shared/Fotter/Fotter';
import ServiceWork from '../../Shared/HowDoseServiceWork/HowDoesServiceWork';
import Pricing from '../../Shared/Pricing/Pricing';
import Services from '../../Shared/Services/Services';
import Banner from '../Banner/Banner';
import BrandTrusts from '../BrandTrusts/BrandTrusts';
import HomePortfolio from '../HomePortfolio/HomePortfolio';


import NavBar from '../Navbar/Navbar';
import Testimonial from '../Testimonial/Testimonial';

const Home = () => {
    return (
        <div id="home" style={{fontFamily:'Lato'}}>
            <NavBar/>
            <Banner/><br/><br/><br/>
            <ServiceWork/>
            <BrandTrusts/>
            <Pricing/>
            <Services/>
            <HomePortfolio/>
            <Testimonial/>
            <Fotter/>
        </div>
    );
};

export default Home;