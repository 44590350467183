import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import priceBannerImg from './PricingBanner.png'
import { HashLink } from 'react-router-hash-link';

const PricingHomeBanner = () => {
    return (
        <Box  textAlign={{xs:'center', md:'start'}} pt={{xs:10, md:15}} sx={{backgroundColor:'#012A55',  color:'white'}}>
            <Grid container  sx={{display:'flex', alignItems:'top', justifyContent:'space-evenly'}}>
                <Grid xs={12} md={5} sx={{py:3}} >
                    <Typography fontSize={{xs:'30px', md:'48px'}} sx={{fontWeight:'bold', fontFamily:'Lato'}}>
                    Simple pricing and <br/> No hidden charge.
                    </Typography>
                    <Typography variant="h6" sx={{fontFamily:'Lato'}}>
                    Budgeting for design projects doesn't have to be so difficult! With all our plans, you will never pay a hidden fee. Our pricing is simple and transparent.
                    </Typography><br/>
                    <Button id="get_start" style={{fontSize:'20px', backgroundColor:'#F59000', fontFamily:'Lato'}} sx={{padding:'10px 20px',}}>
             <HashLink style={{color:'black', fontWeight:'bold', textDecoration:'none', paddingRight:'30px'}} to="/pricing#view_pricing">
             View Pricing
                 </HashLink>
            </Button>
                </Grid>
                <Grid xs={12} md={5}>
                    <img  src={priceBannerImg} alt=" " />
                </Grid>
            </Grid>
        </Box>
    );
};

export default PricingHomeBanner;