
import { Box } from '@mui/system';
import React from 'react';
import NavBar from '../../Home/Navbar/Navbar';
import Fotter from '../../Shared/Fotter/Fotter';
import FaqHomeBanner from '../FaqHomeBanner/FaqHomeBanner';
import FaqQuestion from '../FaqQuestion/FaqQuestion';

const FaqHome = () => {
    return (
        <>
        <Box textAlign={{xs:'center', md:'start'}} id="faq">
            <NavBar/>
            <FaqHomeBanner/><br/><br/>
            <FaqQuestion/><br/><br/>
        </Box>
            <Fotter/>
        </>
    );
};

export default FaqHome;