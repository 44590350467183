import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const Banner = () => {
    return (
        <>
        <Box style={{textAlign:'center',    backgroundColor:'#012A55', color:'white'}} padding={{xs:'50px 0px', md:'70px'}}>
            <Typography variant="h2"  fontSize={{xs:'25px', md:'70px'}} width={{xs:"100%"}} textAlign={{xs:'center', md:'center'}} paddingLeft={{xs:'0px', md:'0px'}} sx={{fontWeight:'bold', pt:7, fontFamily:'Lato',}} >
                Create a story not just a logo <br/>
                to win you more customers.
            </Typography>
            <Typography  variant="h6" fontSize={{xs:'15px', md:'22px'}} paddingX={{xs:'20px'}}  sx={{fontWeight:'400', py:4, fontFamily:'Lato',}}>
                Would you like to learn more about how professional branding can help your business? <br/>
                Stand out from your competitors, attract more customers, and outperform them.
            </Typography>
            <Box justifyContent={{xs:'normal', md:'center'}} style={{display:'flex', flexWrap:'wrap', paddingLeft:'20px', fontWeight:'400',}} sx={{columnGap: 10,pb:3}}>
            <Typography variant="h6"  sx={{ p:2,fontFamily:'Lato',}}>
            <span style={{borderColor:'#F59000'}}>&#9989;</span> 100% Moneyback Guaranteed
            </Typography>
            <Typography variant="h6"  sx={{ p:2,fontFamily:'Lato',}}>
            &#9989;    Dedicated Design Team
            </Typography>
            <Typography variant="h6"  sx={{ p:2,fontFamily:'Lato',}}>
            &#9989;   100% Satisfaction Guaranteed
            </Typography>
            </Box>
                    
             <Button id="get_start" style={{fontSize:'20px', backgroundColor:'#F59000',fontFamily:'Lato', }} sx={{padding:'10px 20px',}}>
             <HashLink style={{color:'black', fontWeight:'bold', textDecoration:'none', paddingRight:'30px'}} to="/#get_start">
                 Get Started Now
                 </HashLink>
            </Button>
        </Box>
        </>
    );
};

export default Banner;