import { Avatar, CardHeader, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const Testimonial = () => {
    return (
        <Box sx={{backgroundColor:'#f4f4f4'}}  fontSize={{xs:'10px', md:'16px', xl:'16px'}} >
            <Typography variant="h3" fontSize={{xs: '25px', md:'50px'}} sx={{fontWeight:'bold', color:'#273438', textAlign:'center', pb:1, py:10, fontFamily:'Lato'}} >
            Our clients love our work and you will too

           </Typography>
            <Grid  container sx={{pb:15}} columns={13} rowGap={5} columnGap={3}  pl={{xs:6, md:20, xl:38 }} pr={{xs:0, md:15, xl:0 }}>
                <Grid  xs={11} md={6} xl={5} style={{border: "1px solid #7D8284"}}  sx={{  py:2,  px:3,backgroundColor:' #f2f2f2',  fontFamily:'Lato' }}>
                <Typography  sx={{ color:'black', }} variant="paragraph" >
                Suvas was such a pleasure to work with. I submitted my requirements less than two days ago and he provided me with concepts that were amazing! I’ve never experienced such a quick turnaround time and such satisfaction. I can’t wait to make my logo public! I will recommend and refer and use Suvas over and over again! Thanks man!
                </Typography>
                <CardHeader
                avatar={
                  <Avatar sx={{  p:4 }}>
                    SJ
                  </Avatar>
                }
                title="Soad Ajaj"
                subheader="December 05, 2021"
              />
                
                </Grid>
                <Grid xs={11} md={6} xl={5} style={{border: "1px solid #7D8284"}}  sx={{  py:2,  px:3,backgroundColor:' #f2f2f2',  fontFamily:'Lato' }}>
                <Typography  sx={{ color:'black',}} variant="paragraph" >
                We have worked with this graphic designer for the second time and are pleased with his reliability, great creative ideas for logo design and perfect execution. The communication is attentively and leads to perfect results. He creates business cards in excellent corporate design. We highly recommend him and would like to work with him again.
                </Typography>
                <CardHeader
                avatar={
                  <Avatar sx={{  p:4 }}>
                    AN
                  </Avatar>
                }
                title="agm33neu"
                subheader="December 03, 2021"
              />
                </Grid>
                <Grid xs={11} md={6} xl={5} style={{border: "1px solid #7D8284"}}  sx={{  py:2,  px:3,backgroundColor:' #f2f2f2',  }}>
                <Typography  sx={{ color:'black', fontFamily:'Lato'}} variant="paragraph" >
                Working with Suvas ("fighter3") to create our logo was a pleasant and easy experience. He did excellent work, on time, and communicated extremely well during the process. Steven Highfill
                </Typography>
                <CardHeader
                avatar={
                  <Avatar sx={{  p:4 }}>
                    SH
                  </Avatar>
                }
                title="
                stevehighfill"
                subheader="December 1, 2021"
              />
                
                </Grid>
                <Grid xs={11} md={6} xl={5} style={{border: "1px solid #7D8284"}}  sx={{  py:2,  px:3,backgroundColor:' #f2f2f2',  }}>
                <Typography  sx={{ color:'black', fontFamily:'Lato'}} variant="paragraph" >
                Suvas has done work for me before and was extremely professional then as he was again this time. I had him create alternate designs of our logo and then a design for a t-shirt. I couldn't be happier with the outcome. I am very happy to recommend him. His prices are also very fair and he meets his quoted timeframes. Thanks for your help Suvas.
                </Typography>
                <CardHeader
                avatar={
                  <Avatar sx={{  p:4 }}>
                    R
                  </Avatar>
                }
                title="raysta68"
                subheader="December 01, 2021"
              />
                </Grid>
                <Grid  xs={11} md={6} xl={5} style={{border: "1px solid #7D8284",  fontFamily:'Lato'}}  sx={{  py:2,  px:3,backgroundColor:' #f2f2f2',  }}>
                <Typography  sx={{ color:'black', }} variant="paragraph" >
                As you can see on Suvas profile- he has already helped me multiple times. I will ONLY go to Suvas AKA fighter3 for all of my graphic design needs for the rest of the future of my business. I trust no one else, and you shouldn't either. Go to Suvas, work with him. He is your man! Consummate professional, perfect communication from across the world, and talent like no other. 10/10. 5 Stars.
                </Typography>
                <CardHeader
                avatar={
                  <Avatar sx={{  p:4 }}>
                    KG
                  </Avatar>
                }
                title="KennGuyen"
                subheader="December 05, 2021"
              />
                
                </Grid>
                <Grid xs={11} md={6} xl={5} style={{border: "1px solid #7D8284",  fontFamily:'Lato'}}  sx={{  py:2,  px:3,backgroundColor:' #f2f2f2',  }}>
                <Typography  sx={{ color:'black',}} variant="paragraph" >
                The seller was very professional and patient with me. I had questions on my files and he always responded promptly. He was great with the revisions and I'm very happy with the outcome of the logo, it's even better than I thought it would be. I believe I'll work with the seller again for other design needs for my business. I highly recommend :-)
                </Typography>
                <CardHeader
                avatar={
                  <Avatar sx={{  p:4 }}>
                    DB
                  </Avatar>
                }
                title="designsbyshante"
                subheader="December 03, 2021"
              />
                </Grid>
           
            </Grid>
        </Box>
     
    );
};

export default Testimonial;