import { Box } from '@mui/system';
import React from 'react';
import FaqQuestion from '../../FAQ/FaqQuestion/FaqQuestion';
import BrandTrusts from '../../Home/BrandTrusts/BrandTrusts';
import NavBar from '../../Home/Navbar/Navbar';
import Fotter from '../../Shared/Fotter/Fotter';
import Pricing from '../../Shared/Pricing/Pricing';
import CustomLogoBanner from '../CustomLogoBanner/CustomLogoBanner';
import CustomLogoBanner2 from '../CustomLogoBanner2/CustomBannerLogo2';

const CustomeLogoHome = () => {
    return (
        <>
        <Box textAlign={{xs:'center', md:'start'}} id="custom_logo">
            <NavBar/>
            <CustomLogoBanner/>
            <BrandTrusts/>
            <Pricing/>
            <CustomLogoBanner2/>
            </Box><br/><br/><br/>
            <FaqQuestion/>
            <Fotter/>
        </>
    );
};

export default CustomeLogoHome;