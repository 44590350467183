import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const PortfolioHomeBanner = () => {
    return (
        <Box pt={{xs:10, md:20}} pb={{xs:5, md:15}}  sx={{backgroundColor:'#012A55', color:'white', textAlign:'center'}}>
            <Typography fontSize={{xs:'25px', md:'48px',}} px={{xs:1, md:35}} sx={{lineHeight:1.2, fontWeight:'bold', fontFamily:'Lato'}} >
                Here's some of our recent favourite custom logo designs
            </Typography>
            <Typography fontSize={{xs:'12px', md:'20px',}} px={{xs:1, md:30}} sx={{pt:2, fontFamily:'Lato'}}>
            Take a look at some of or work we've created for startups and small business all around the world. Every logo is created to not just look great, but to work for your business.
            </Typography>
        </Box>
    );
};

export default PortfolioHomeBanner;