import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const ContactHomeBanner = () => {
    return (
        <Box sx={{backgroundColor:'#012A55',}} pt={{xs:10, md:15}} pb={{xs:10, md:10}}>
           <Typography variant="h3" fontSize={{xs:'25px', md:'70px'}} sx={{fontWeight:'bold', color:'white', textAlign:'center', fontFamily:'Lato'}} >
               Contact us today
           </Typography>
        </Box>
    );
};

export default ContactHomeBanner;