import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { HashLink } from 'react-router-hash-link';
import './FaqQuestion.css'

export default function FaqQuestion() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
      <>
    <div class="faq_width" style={{ margin:'auto'}}>
    <Typography variant="h3" fontSize={{xs:'20px', md:'48px'}} sx={{color:'#F59000', pb:10, textAlign:'center', fontFamily:'Lato'}} px={{xs:2, md:20}}>
    Frequently Asked Questions
            </Typography>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography variant="h5" fontSize={{xs:'18px', md:'1,5rem'}} sx={{  flexShrink: 0, color:'#273438',  fontFamily:'Lato' }}>
          What is needed to get started?
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" sx={{color:'#7D8284', fontWeight:'normal', fontFamily:'Lato'}}>
          All the details requested for the logo design will be automatically asked while you place an order. Check 'Order Process' Page to get started sucessfully.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
           <Typography variant="h5" fontSize={{xs:'18px', md:'1,5rem'}} sx={{  flexShrink: 0, color:'#273438',  fontFamily:'Lato' }}>
           What happens if I don't like my design?
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" sx={{color:'#7D8284', fontWeight:'normal'}}>
          If you are not satisfied with our initial logo design concepts, we will ask you for your feedback to have another attempt at designing your logo design concepts. If you still do not like our designs, we will give you a full refund.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
           <Typography variant="h5" fontSize={{xs:'18px', md:'1,5rem'}} sx={{  flexShrink: 0, color:'#273438' }}>
           What is the use of source/Vector files?
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" sx={{color:'#7D8284', fontWeight:'normal'}}>
          Source/Vector files are the editable versions of the designs which are highly scalable and used for EDITING and PRINTING purpose without loose of quality.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
           <Typography variant="h5" fontSize={{xs:'18px', md:'1,5rem'}} sx={{  flexShrink: 0, color:'#273438',  fontFamily:'Lato' }}>
           What is include in Social Media Kit?
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" sx={{color:'#7D8284', fontWeight:'normal',  fontFamily:'Lato'}}>
          
Profile and cover photos for three social media accounts of your choice e.g Facebook, Twitter, Linkedin.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
           <Typography variant="h5" fontSize={{xs:'18px', md:'1,5rem'}} sx={{  flexShrink: 0, color:'#273438', fontFamily:'Lato' }}>
           When will I get my final files?
          </Typography>
         
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6" sx={{color:'#7D8284', fontWeight:'normal',  fontFamily:'Lato'}}>
          
          Once you are happy with your logo and you are ready to go ahead with it, will send you a large pack of hi-res file formats for web and print including vector formats.<br/>
You can request any sizes, colours or file types if you need more.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div><br/><br/><br/>
     <Box sx={{textAlign:'center', py:10,backgroundColor:'#f4f4f4'}}>
     <Typography variant="h3" fontSize={{xs:'20px', md:'48px'}} sx={{color:'#F59000', fontFamily:'Lato'}} px={{xs:2, md:20}}>
     Not finding what you are looking for?
            </Typography>
            <Typography variant="h5"  fontSize={{xs:'18px'}}  sx={{color:'#7D8284', pt:1, fontFamily:'Lato'}} px={{xs:2, md:20}}>
            Chat with us or send us an email.
            </Typography>
            <Button id="get_start" style={{fontSize:'20px', backgroundColor:'#F59000', padding:'10px 20px',}} sx={{mt:5}}>
             <HashLink   style={{color:'black', fontWeight:'bold',textAlign:'center', textDecoration:'none',  fontFamily:'Lato',paddingRight:'30px'}} to="/conatact#conatact">
             Contact Us
                 </HashLink>
            </Button>
     </Box>
    </>
  );
}
