import { Box } from '@mui/system';
import React from 'react';
import NavBar from '../../Home/Navbar/Navbar';
import Fotter from '../../Shared/Fotter/Fotter';
import Blogs from '../Blogs/Blogs';

const BlogHome = () => {
    return (
        <Box id="blog">
            <NavBar/>
            <Blogs/>
            <Fotter/>
        </Box>
    );
};

export default BlogHome;