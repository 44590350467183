import Home from './pages/Home/Home/Home';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import CustomeLogoHome from './pages/CustomLogoSite/CustomLogoHome/CustomeLogoHome';
import OrderProcessHome from './pages/OrderProcess/OrderProcessHome/OrderProcessHome';
import PricingHome from './pages/Pricing/PricingHome/PricingHome';
import PortfolioHome from './pages/Portfolio/PortfolioHome/PortfolioHome';
import AboutHome from './pages/About/AboutHome/AboutHome';
import ContactUsHome from './pages/ContactUs/ContactUsHome/ContactUsHome';
import FaqHome from './pages/FAQ/FaqHome/FaqHome';
import BlogHome from './pages/Blog/BlogHome/BlogHome';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/custom_logo" element={<CustomeLogoHome />} />
      <Route path="/order_process" element={<OrderProcessHome />} />
      <Route path="/pricing" element={<PricingHome />} />
      <Route path="/portfolio" element={<PortfolioHome />} />
      <Route path="/about" element={<AboutHome />} />
      <Route path="/faq" element={<FaqHome />} />
      <Route path="/conatact" element={<ContactUsHome />} />
      <Route path="/blog" element={<BlogHome />} />
    </Routes>
  </BrowserRouter>
    </div>
  );
}

export default App;
