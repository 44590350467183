import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import './CustomeLogoBanner.css';
import customBannerImg from './CustomBannerImage.png'

const CustomLogoBanner = () => {
    return (
        <Box height={{xs:"100%", md:"516px"}} className="Banner" sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={7}>
          <Typography variant="h3" fontSize={{xs:'30px', md:'48px'}} pl={{xs:0, md: 10,}} pt={{xs:10, md:20}} sx={{fontWeight:'bold', lineHeight:1.3, pb:2,  fontFamily:'Lato'}}>
            Design a custom logo that <br/>
            makes your brand stand out.
          </Typography>
          <Typography variant="h6" fontSize={{xs:'15px', md:'17px'}} pl={{xs:1, md: 10,}} pr={{xs:1}}  sx={{ lineHeight:1.5, color: '#f4f4f4',  fontFamily:'Lato'}}>
          We begin the process of designing a custom logo at our studio by doing a brand analysis, research, brainstorming, logo development, logo design, presentation variations, and ultimately brand deliverables.
          </Typography>
        </Grid>
        <Grid    xs={12} md={5} >
        <img  className="CustomLogoBannerImg" src={customBannerImg} alt=" "/>
        </Grid>
      </Grid>
    </Box>
    );
};

export default CustomLogoBanner;