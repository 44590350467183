import { Box } from '@mui/system';
import React from 'react';
import HomePortfolio from '../../Home/HomePortfolio/HomePortfolio';
import NavBar from '../../Home/Navbar/Navbar';
import Fotter from '../../Shared/Fotter/Fotter';
import AboutBanner from '../AboutBanner/AboutBanner';
import AboutTeam from '../AboutTeam/AboutTeam';

const AboutHome = () => {
    return (
        <Box id="about">
            <NavBar/>
            <AboutBanner/>
            <AboutTeam/>
            <HomePortfolio/>
            <Fotter/>
        </Box>
    );
};

export default AboutHome;